.actions-row {
    display:flex;
    flex-direction:row;
    margin-top: 50px;
}

.trip_main_details {
    md-menu {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 2px;
        background-color: #0178c2;
        width: 100px;
        height: 32px;
        margin-top: auto;
        color: white;
        margin-left: 10px;

        .md-icon-button {
            width: auto !important;
        }
    }

    button {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 2px;
        background-color: rgb(1, 120, 194);
        width: 83px;
        height: 32px;
        color: white;

        &>div {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            color: white;
        }
    }
}

.md-select-value{
    border-bottom: 0px;
}

.client-search{
    width: 250px;
    border: solid 1px #c6cbd4;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
}

.margin-right{
    margin-right: 20px;
}

.margin-left{
    margin-left: 300px;
}

.vendor-search-input {
    width: 419px;
    height: 32px;
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
    border: solid 1px #c6cbd4;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: normal;
    color: rgb(158, 158, 158);
    padding-left: 16px;
    margin-right: 20px;
}

#form_holder {
    display: flex;
    justify-content: space-between;
    margin-left: auto;

    #input-select-options {
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        width: 146px;
        height: 32px;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #c6cbd4;

        span {
            margin-right: 8px;
            font-family: "Open Sans Hebrew";
            font-size: 12px;
            color: rgb(33, 33, 33);
        }
    }
}


table{
    // border-top: 1px solid rgba(0, 0, 0, 0.35);
    &>thead>tr>td {
        opacity: 1.0;
        font-family: "Open Sans Hebrew";
        font-size: 14px;
        font-weight: bold;
        line-height: 1.71;
    }

    a {
        font-family: "Open Sans Hebrew";
        font-size: 14px;
        line-height: 1.71;
        color: rgb(0, 134, 217);
    }

    & td {
        font-family: "Open Sans Hebrew" !important;
        font-size: 14px !important;

        .ico {
            visibility: hidden;
            height: 24px;
            width: 24px;
            cursor: pointer;
        }

        & :hover {
            .fa-trash {
                visibility: visible;
            }
        }

        .fa-trash {
            color: #d31847;
            visibility: hidden;

        }

        .fa-copy {
            color: #0178c2;
            visibility: hidden;
        }
    }

    & tr:hover i {
        visibility: visible;
    }
}

.cancelled {
    background-color: #eeeeee !important;
    opacity: 0.9;
    .ng-binding {
        color: #9e9e9e !important;
    }
    input{
        background-color: #eeeeee !important;
    }
}

.select-status-main{
    width: 150px;
    margin: auto;
}

.vendors-table-container {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
    background-color: rgb(255, 255, 255);
    border-top: rgba(1, 120, 194, 0.6) solid 10px;
    margin-top: 16px;
    .fa-copy {
        color:#0178c2 !important;
    }
    .fa-plus-circle {
        color:#0178c2 !important;
    }
    thead {
        tr {
            box-shadow: inset 0px 0px 0px 0px #0178c2 !important;
            td.active {
                background-color:white !important;
            }
        }
    }
    tr {
        &:first-child {
            td {
                border-top:none !important;
            }
        }
    }

    #trip_count {
        margin-left: 24px;
        top: 18px;
        margin-top: 18px;
        position: relative;
        margin-bottom: 32px;
        font-family: "Open Sans Hebrew";
    }
}

.main-table{
    .td_active {
        opacity:1;
        background-color:white;
        color:#0178c2;
    }
}

.requests-table{
    margin: 20px;
    padding-bottom: 15px;
}

top-bar {
    position:fixed;
    width: 100%;
    z-index: 5;
}

#itineraries_top_bar {
    padding: 14px 59px 0px;
    background-color: #91a7b4;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
    display: flex;
    flex-direction: column;

    input {
        width:100% !important
    }
    select {
        width:100% !important;
    }

    form {
        input {
            border: 1px solid transparent;
            border-radius: 2px 2px 2px 2px !important;
            background-color: rgb(255, 255, 255);
        }

        select {
            border: 1px solid transparent;
            border-radius: 2px 2px 2px 2px !important;
            background-color: rgb(255, 255, 255);
            -webkit-appearance: none;
            -webkit-border-radius: 0px;
        }

        margin-left: 34px !important;
    }

    .title-blue {
        font-family: "Open Sans";
        font-size: 14px;
        color: rgb(250, 250, 250);
        font-weight: initial !important;
        margin-bottom: 0px;
    }

    .mainNavNonePointer{
        pointer-events: none;
        opacity: 0.8;
    }

    #itineraries_nav_buttons {
        /*position: relative;
        top: 60px;*/
        list-style: none;
        display: flex;
        padding-top: 10px;

        .buttonActive {
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: 600;
            color: rgb(0, 40, 65);
            border-bottom: 2px solid rgb(0, 40, 65);
            background-color: initial !important;
        }

        li {
            cursor: pointer;
            width: 170px;
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: normal;
            color: #f5f5f5;
            border-bottom: 3px solid #f5f5f5;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .long {
        width: 100% !important;
    }
}
  
  .animate-show.ng-hide-add.ng-hide-add-active,
  .animate-show.ng-hide-remove.ng-hide-remove-active {
    -webkit-transition:all linear 0.1s;
    transition:all linear 0.1s;
  }
  
  .animate-show.ng-hide {
    line-height:0;
  }

.top-bar-close{
    position: absolute;
    top: 8px;
    right: 22px;
    height: 25px;
    width: 25px;
    text-align: center;
    font-size: 19px;
    font-weight: 600;
    background: #ffffff;
    color: #91a7b4;
    border-radius: 50%;
}

.top-bar-botton{
    transition: all linear 0.5s;
    width: 100%;
    height: 25px;
    margin-left: -15px;
    background-color: #91a7b4;
    font-size: 19px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
}

.table-pre-title,
.table-after-title {
    margin: 0px 0px 0px
}

#room_heading {
    width: 100%;
    height: 45px;
    background-color: rgba(1, 120, 194, 0.6);
    color: white;
    font-weight: normal;
    font-family: "Open Sans";
    display: flex;
    justify-content: left;
    align-items: center;

    span {
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 600;
        color: rgb(255, 255, 255);
        margin-left: 12px;
    }

    .client-table table thead td {
        background: transparent;
        background-color: transparent;
    }
}

.container-itineraries {
    margin: 140px 40px 0 40px;
}

.container-itineraries-top {
    margin-top: 30px;
}

.room_first_name {
    // marg42px;
    width: 140px;
}

.room_last_name {
    width: 195px;
}

.room_age {
    width: 47px;
}

.room_phone {
    width: 128px;
}

.room_email {
    width: 195px;
}

.room_special_request {
    width: 178px;
}

.main-style-button {
    display: flex;
    width: 85px;
    justify-content: space-around;
    margin-left: auto;
}

.comments_input {
    transition: opacity 100ms;
    margin-left: 10px;
    border: none !important;
    color: #000;
    width: auto;
    max-width: 90% !important;
    resize: none;
    min-height: 25px;
    overflow: hidden;
    background-color: initial;
    padding: 0px 0px 0px 0px;
    top: 3px;
    position: relative;

    &:hover,
    &:focus {
        opacity: 1;
    }

    &:placeholder-shown {
        opacity: 0;
    }
}

.comments_td .comments_input:hover {
    opacity: 1;
}

.comment-icon {
    background-image: url('/images/icons/comment.svg');
    background-repeat: no-repeat;
}

.roomsInfo {
    $c: &;

    input {
        border-radius: 2px;
        border: solid 1px var(--white) !important;
    }

    i {
        height: 24px;
        width: 24px;
        position: relative;
        top: 4px;
    }

    tr:last-child {
        td {
            justify-content: initial !important;
        }
    }
}

.readMore {
    margin-left: 16px;
    width: 6%;
    color: #0178c2;
    text-decoration: none !important;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
}

.input_row {
    display: flex;
    width: 100%;
    border-radius: 2px;
    align-items: center;
    height: 48px;

    &>input {
        border: #c6cbd4 1px solid !important;
    }

    &>input:first-child {
        width: 225px;
    }

    &>input:last-child {
        width: 100%;
    }

    .second_dropdown {
        margin-left: 10px;
    }

    .input_space {
        width: 50%;
        display: flex;
        align-items: center;

        md-select {
            width: 40%;
        }
    }
}

.client-table {
    margin: 20px 20px;
    padding: 20px 20px;

    form {
        display: flex;
        flex-direction: column;
    }


    label {
        font-family: "Open Sans Hebrew";
        font-size: 14px;
    }

    tr {
        width: 100%;

        td {
            width: 8%;
        }
    }
}

#trip_main_details {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    input {
        height: 32px;
    }

    div {
        display: flex;
        flex-direction: column;
    }

    div {
        margin-left: 12px;
    }

    div:first-child {
        margin-left: 0;
    }

    &>button {
        position: relative;
        bottom: 0px;
    }

    .datepicker_container {
        border-radius: 2px;
        background-color: white;
        height: 32px;
        width: 179px;
        display: flex;
        flex-direction: row;
        border: solid 1px silver;
        box-sizing: border-box;
        margin-left: initial;
        justify-content: space-between;
        overflow: hidden;

        .md-icon-button {
            margin: 0 0px;
        }

        i {
            color: #0178c2;
            position: relative;
            right: 14px;
        }
    }
    .trip-start-cell{
        width: 100%;
        border: 0px;
    }

    md-datepicker {
        border-radius: 2px;
        background-color: white;
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;

        input {
            padding: 5px;
        }
    }
}

#trip_days_container {
    margin-left: 25px;
}

md-virtual-repeat-container {
    height: 150px;
}

.mainPlan {
    .trip_day {
        background-color: #277aaea1;
    }
}

.save-button {
    position: relative;
    margin-left: auto;
    width: 302px;
}

.trip_day {
    height: 45px;
    background-color: #edb5a4;
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .drag_icon {
        margin-left: 20px;
    }

    .down_icon {
        margin-left: 14px;
    }

    .up_icon {
        margin-left: 5px;
    }

    .trip_day_text {
        width: 64px;
        height: 24px;
        border-radius: 12px;
        background-color: rgb(255, 255, 255);
        margin-left: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .trip_date_human {
        width: 205px;
        font-family: "Open Sans Hebrew";
        font-size: 16px;
        font-weight: 400;
        font-style: italic;
        color: white;
        margin-left: 15px;
        margin-right: 15px;
    }

    .trip_info_icon {
        margin-right: 20px;
        color: rgb(255, 255, 255);
    }

    .trip_select_daily_plan {
        margin-left: 24px;
        width: 290px;
        height: 24px;
        border-radius: 2px;
        background-color: white;
        display: flex;
        justify-content: left;
        font-family: "Open Sans Hebrew";
        font-size: 12px;
        line-height: 2;
        color: rgb(1, 120, 194);
        padding-left: 10px;
    }

    .trip_actions {
        // width:10%;
        margin-left: auto;
        margin-right: 15px;
        color: white;
        display: flex;
        align-items: center;

        .trip_day_trash_icon {
            margin-left: 29px;
        }
    }
}


.trip_day_details_row {
    margin-left: 1%;
    margin-right: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 12px;
    color: #757575;

    .div-col {
        display: flex;
        flex-direction: column;

        input{
            border-radius: 2px;
            border: solid 1px #eeeded;
            background-color: #ffffff;
            height: 30px;
        }
    }

    .md-datepicker-input{
        min-width: 7vw;
        max-width: 7vw;
    }

    i {
        margin-top: 5px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .category_field_details {
        min-width: 9.35vw;
        max-width: 9.35vw;
    }

    .vendor_field_details {
        min-width: 10.82vw;
        max-width: 10.82vw;
    }

    .item_field_details {
        min-width: 10.55vw;
        max-width: 10.55vw;
    }

    .adults_field_details {
        min-width: 1.6vw;
        max-width: 1.6vw;
    }

    .child_field_details {
        min-width: 1.6vw;
        max-width: 1.6vw;
    }

    .home_field_details {
        min-width: 1.6vw;
        max-width: 1.6vw;
    }

    .car_field_details {
        min-width: 1.6vw;
        max-width: 1.6vw;
    }

    .end_field_details {
        min-width: 7vw;
        max-width: 7vw;
    }

    .days_field_details {
        min-width: 4.35vw;
        max-width: 4.35vw;
    }

    .from_field_details {
        min-width: 5.44vw;
        max-width: 5.44vw;
    }

    .to_field_details {
        min-width: 5.44vw;
        max-width: 5.44vw;
    }

    .formula_field_details {
        min-width: 8.44vw;
        max-width: 8.44vw;
    }

    .rate_field_details {
        min-width: 4vw;
        max-width: 4vw;
    }

    .currency_field_details {
        min-width: 4.1vw;
        max-width: 4.1vw;
        margin: 0px;
    }

}

.comment_row {
    width: 100%;
    flex-direction: row;
    padding: 0px 23px;
    margin-bottom: 20px;
}

.itinerary_days_action {
    visibility: hidden;
    display: flex;
    flex-direction: row;
    width: 100px;
    justify-content: space-between;
}

#trip_pricing {
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
    background-color: rgb(255, 255, 255);

    h4 {
        width: 100%;
        height: 45px;
        background-color: rgba(1, 120, 194, 0.6);
        color: #ffffff;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 10px;
    }

    .costs-con{
        display: block;
        margin: auto;
        width: 90%;
    }

    .costs-border{
        height: 2px;
        background-color: #d8d8d8;
    }

    .width-auto{
        width: auto;
        margin-right: 5px;
    }

    .costs-total-row{
        color: #ffffff;
        font-weight: 600;
        padding-left: 11px;
        background-color: #BBBBBB;
        border-radius: 10px;
        margin-bottom: 10px;

        .total-price-span{
            padding-left: 10px;
            width: 270px;
        }
    }

    ul {
        list-style: none;
        padding: 0px;

        li {
            height: 49px;
            display: flex;
            align-items: center;
            font-family: "Open Sans Hebrew";
            font-size: 14px;
            color: rgb(117, 117, 117);
            margin-bottom: 3px;

            button {
                margin: initial;
                padding: initial;
            }

            .mu_dropdown {
                display: flex;
                align-items: center;

                span {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    span {
                        width: auto;
                    }
                }

                md-select {
                    margin-left: 8px;
                }

                .md-select-value {
                    min-width: auto;
                }
            }

            span {
                width: 280px;

                i {
                    color: rgb(1, 120, 194);
                }

            }
        }
    }
}

md-tooltip{
    font-size: 14px !important;
}


.booking_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
    background-color: white;

    h4 {
        // width: 643px;
        color: white;
        height: 45px;
        background-color: rgba(1, 120, 194, 0.6);
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        align-items: center;
        padding-left: 10px;
        margin-top: 0px;

        i {
            font-size: 25px;
        }
        :focus {
            outline: none;
        }
    }

    .booking-tb-con {
        width: 96%;
        margin-top: 10px;
        margin-bottom: 13px;
        display: block;
        margin: auto;
    }

    .booking-tb{
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: solid 1px #c6cbd4;
    }

    .booking-tb-row{
        min-width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;
        label{
            opacity: 0.5;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            color: #757575;
            margin-bottom: 15px;
            height: 46px;
        }
        input {
            border: none;
            min-width: 100%;
            max-width: 100%;
        }
        input:focus {
            outline:0;
        }
    }

    .booking-tb-in-blue{
        color: #0178c2;
    }

    .booking-tb-cell-s{
        min-width: 2%;
        max-width: 2%;
    }

    .booking-tb-cell-m{
        min-width: 5%;
        max-width: 5%;
    }

    .booking-tb-cell-l{
        min-width: 10%;
        max-width: 10%;
    }

    .booking-tb-cell-xl{
        min-width: 16%;
        max-width: 16%;
    }

    .booking-value{
        height: 20px;
        background: none;
    }

    .booking-select-currency{
        min-width: 80%;
        max-width: 80%;
    }

    .booking-comments-row {
        width: 100%;
        margin-left: 23px;
        height: 50px;
        margin-bottom: 10px;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .booking-comments-icon{
        width: 21px;
        height: 21px;
        color: #0178c2;
        margin-right: 15px;
    }

    .booking-comments-in{
        width: 90%;
        border: 0px;
        font-size: 12px;
    }
    .booking-comments-in:focus {
        outline:0;
    }

}

#book_bottom_row {
    font-family: "Open Sans Hebrew";
    font-size: 16px;
    color: rgb(117, 117, 117);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 16px;

    span {
        span {
            margin-left: 10px;
            font-weight: 600;
            color: rgb(33, 33, 33);
        }

        .highlight {
            color: #0178c2;
        }

        input{
            border: 0px;
            background: none;
            width: 80px;
        }:focus {
            outline:0;
        }

    }
    .highlight {
        color: #0178c2;
    }
}


.small_field_details {
    width: 50px;
    margin-left: 40px;
}

.currency_field_details {
    width: 76.6px;
    margin-left: 34px;
}

.paid_field_details {
    width: 40px;
    margin-left: 34px;
}

.due_field_details {
    width: 40px;
    margin-left: 40px;
}

.code_field_details {
    width: 85px;
    margin-left: 35px;
}

.reservation_field_details {
    width: 185px;
    margin-left: 35px;
}

.btn-switch {
    position: relative;
    display: block;
    width: 55px;
    height: 22px;
    cursor: pointer;
    background-color: rgb(158, 158, 158);
    border: 2px solid rgb(158, 158, 158);
    border-radius: 40px;

    .btn-switch-circle {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 18px;
        width: 18px;
        background-color: #fff;
        border-radius: 40px;
    }
}

.btn-switch:focus {
    outline:0;
}

.btn-switch--on {
    background-color: #44c97a;
    border: 2px solid #44c97a;

    .btn-switch-circle--on {
        left: auto;
        right: 0;
    }
}

#trip_planning_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#details_toggle {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    width: 13%;
    flex-direction: row !important;
    margin-top: auto;
    position: relative;
    top: -5px;
    margin-left: auto !important;

    .btn-switch {
        margin-left: initial !important;
    }
}

.selected-txt{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #44c97a;
}

.not-selected-txt{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757575;
}

#document_section {
    .document_header_section {
        top: -8px;
        position: relative;
        background-color:initial !important;
        display:flex;
        border:none !important;
        box-shadow:none !important;
    }
    &>div {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
        background-color: rgb(255, 255, 255);

        .document_heading {
            background-color: rgb(166, 178, 209);
            width: 100%;
            height: 45px;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 10px;

            div:last-child {
                margin-left: 10px;
            }
        }
    }
}

.document-item{
    border-bottom:solid 1px #c6cbd4;
}

.document-item-con{
    width: 97%;
    display: block;
    margin: auto;
}

.document-item-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin-top: 15px;
}

.document-item-name{
    min-width: 20%;
    max-width: 20%;
}

.document-item-cabintype{
    min-width: 20%;
    max-width: 20%;
}

.document-item-nameofitem{
    min-width: 20%;
    max-width: 20%;
}

.document-item-select-cell{
    min-width: 40%;
    max-width: 40%;
}

.document-item-select{
    min-width: 100%;
    max-width: 100%;

    height: 35px;
    border-radius: 2px;
    border: solid 1px #c5c4c4;
    background-color: #ffffff;
}

.document-item-details{
    min-width: 3%;
    max-width: 3%;
}

.document-icon-details{
    margin-left: 3px;
    color: #0178c2;
}

.document-item-confCode{
    min-width: 9%;
    max-width: 9%;
    text-align: center;
}

.document-item-action{
    min-width: 4%;
    max-width: 4%;
    display: flex;

    .fa-plus-circle {
        color:#0178c2 !important;
    }
}

.document-item-action-addRow{
    margin: auto;
    display: block;
}

.document-comment-row {
    display: flex;
    justify-content: left;
    align-items: flex-end;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 15px;
}

.document-icon{
    width: 21px;
    height: 21px;
    color: #0178c2;
}

.document-comment-in{
    width: 90%;
    border: 0px;
    height: 25px;
}

.document-header{
    width: 202px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    margin-bottom: 10px;
    margin-top: 32px;
}

#btn-actions{
    width: 95px;
    height: 32px;
    margin-top: 25px;
}
.btn-actions{
    color: white;
    width: 95px;
    height: 32px;
    border:none;
    border-radius: 2px;
    background-color: #0178c2;
    &:hover {
        background-color: #015996;
        color: #e5e5e5;
    }
}

.btn-actions-1{
    color: white;
    width: 95px;
    height: 25px;
    border:none;
    border-radius: 2px;
    background-color: #0178c2;
    &:hover {
        background-color: #015996;
        color: #e5e5e5;
    }
}

.btn-actions-2{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;

    color: white;
    width: 68px;
    height: 34px;
    border:none;
    border-radius: 4px;
    background-color: #0178c2;
    &:hover {
        background-color: #015996;
        color: #e5e5e5;
    }
}

.document-plan{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #004169;
}

.trip_name_container{
    height: 57px;
    margin-right: 10px;
}

.trip_smal_in{
    width: 86px;
}

.create-doc{
    width: 530px;
    height: 369px;
    border-radius: 2px;
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12);
    background-color: #fafafa;
    border-top: 0px;
}

.create-doc-head{
    width: 530px;
    height: 16%;
    border-radius: 2px;
    background-color: #0178c2;
    display: flex;
    align-items: center;
}

.create-doc-head-txt{
    font-size: 20px;
    color: #ffffff;
    margin-left: 20px;
}

.create-doc-con{
    width: 100%;
    height: 65%;
    border-bottom: 1px solid #9e9e9e;
}

.create-doc-youSure{
    margin-left: 20px;
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #212121;
}

.create-doc-icon{
    margin-right: 20px;
    color: #f8b953;
    font-size: 20px;
}

.create-doc-warning{
    margin-left: 65px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: normal;
    color: #212121;
}

.create-doc-buttons{
    width: 98%;
    height: 25%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
}

.create-doc-cancel{
    width: 93px;
    height: 32px;
    color: #0178c2;
    border: solid 1.5px #0178c2;
    border-radius: 2px;
    background-color: #ffffff;
    margin-right: 20px;
    &:hover {
        background-color: #fafafa;
        color: #015996;
    }
}

.create-doc-yes{
    width: 93px;
    height: 32px;
    color: white;
    border:none;
    border-radius: 2px;
    background-color: #0178c2;
    margin-right: 20px;
    &:hover {
        background-color: #015996;
        color: #e5e5e5;
    }
}

.editable-controls {
    display: flex !important;

    .editable-buttons {
        display: flex !important;

        button {
            width: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
        }
    }
}

#edit_document_box {
    width: 530px;
    height: 369px;
    border-radius: 2px;
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12);
    background-color: rgb(250, 250, 250);

    h4 {
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 10px;
        background-color: rgba(1, 120, 194, 0.6);
        height: 45px;
        color: white;
    }

    .edit_document_text {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #9e9e9e !important;
        height: 200px;
        padding: 5px 5px;

        textarea {
            border: none;
            height: 100%;
        }

        .edit_document_date {
            color: #9e9e9e;
        }
    }

    #edit_document_buttons {
        display: flex;
        width: 35%;
        margin-left: auto;
        margin-right: 5%;
        height: 100%;
        margin-top: 42px;
        justify-content: space-between;

        .document_close_button {
            border: #0178c2 1px solid;
            color: #0178c2;
            background-color: initial;
        }

        .document_save_button {
            border: #0178c2 1px solid;
        }
    }
}

#create_html_box {
    width: 530px;
    height: 369px;
    border-radius: 2px;
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12);
    background-color: rgb(250, 250, 250);

    h4 {
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 10px;
        color: white;
        background-color: rgba(1, 120, 194, 0.6);
        height: 45px;
    }

    .edit_document_text {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #9e9e9e !important;
        height: 200px;
        padding: 5px 5px;

        i {
            color: #f8b953;
            margin-left: 24px;
        }

        span {
            font-weight: bold;
            margin-left: 20px;
        }

        div:last-child {
            margin-top: 20px;
            margin-left: 62px;
        }
    }

    #edit_document_buttons {
        display: flex;
        width: 35%;
        margin-left: auto;
        margin-right: 5%;
        height: 100%;
        margin-top: 42px;
        justify-content: space-between;

        .document_close_button {
            border: #0178c2 1px solid;
            color: #0178c2;
            background-color: initial;
        }

        .document_save_button {
            border: #0178c2 1px solid;
        }
    }
}

.glyphicon-ok:before {
    content: "\f00c";
}

md-select {
    margin: initial;
}

.itinerary_days_list {
    list-style-type: none;
    margin: initial;
    padding: initial;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
    background-color: white;

    .trip_day_details_row {
        display: flex;
        justify-content: space-between;
        position: relative;
    }
}

.daily-trip-day-header:focus {
    outline:0;
}

.menudemoBasicUsage .md-menu-demo {
    padding: 24px;
}

.menudemoBasicUsage .menu-demo-container {
    min-height: 200px;
}


md-dialog {
    // margin-left: auto;
    margin-right: 52px;
    border-top: rgba(1, 120, 194, 0.6) solid 10px;
}

.md-dialog-container {
    top: 0px !important;
    position: fixed;
}

.cruise-more-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    width: 225px;
    height: 160px;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
    background-color: #fafafa;
    border-top: #0178c2 solid 10px;
}

.cruise-more-con {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 160px;
    margin-top: 15px;
    margin-bottom: 15x;
}

.cruise-more-select{
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 186.6px;
    height: 35px;
    border-radius: 2px;
    border: solid 1px #d6d6d6;
    border-bottom: 0px;
    background-color: #ffffff;
    margin-bottom: 6px;
}

.cruise-more-point-row{
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 186px;
    height: 33px;
    border-radius: 2px;
    border: solid 1px #d6d6d6;
    background-color: #ffffff;
}

.cruise-more-point{
    width: 90px;
    height: 31px;
    border: 0px;
    text-align: center;
    color: #212121;
    font-size: 14px;
    background-color: #ffffff;
}
.cruise-more-point:focus {
    outline:0;
}

.cruise-more-point-border-between{
    width: 1px;
    height: 21px;
    margin-top: 5px;
    background-color: #d6d6d6;
}

md-checkbox {
    margin-left: 11px !important;
}

.car-miles-con{
    border-top: #0178c2 solid 10px;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
    background-color: #fafafa;
}

.car-miles-row{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 263px;
    height: 73px;
}

.car-miles-cell{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.car-miles-in{
    width: 75px;
    height: 35px;
    border-radius: 2px;
    border: solid 1px #cacaca;
    background-color: #ffffff;
    margin-right: 10px;
}

#car-miles-way1{
    margin: 0px;
}

.pricing-con-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pricing-con{
    width: 48%;
}

.pricing-text{
    width: 320px;
    height: 26px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    margin-bottom: 10px;
    margin-top: 10px;
}

.notIncludeTitle {
    margin-top: 40px;
    margin-left: 0px;
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: center;

    md-select: {
        width: 50%;
    }
}

.not-included-text {
    width: auto;
    height: 19px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    margin-right: 20px;
}

.pricing-select{
    min-width: 360px;
    max-width: 360px;
    height: 32px;
    border-radius: 2px;
    border: solid 1px #c6cbd4;
    background-color: #ffffff;
    text-align: center;
}

.md-select-value{
    border-bottom: 0px;
}



.title-blue {
    width: auto;

    md-select {
        margin-right: auto;
    }
}



.dndDragging {
    opacity: 0.7;
}

.dndDraggingSource {
    display: none;
}

.dndPlaceholder {
    background-color: #ddd;
    display: block;
    min-height: 42px;
}

#save_plan_button {
    margin-left: auto;
    border-radius: 2px;
    border: #0178c2 1px solid;
    background-color: white;
    color: #0178c2;
    width: 118px !important;
    height: 32px !important;
    margin-top: 20px;
    float: right;
}


.navBardemoBasicUsage md-content .ext-content {
    padding: 50px;
    margin: 20px;
    background-color: #FFF2E0;
}

person-row,
new-person-row {
    width: 100% !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
    background-color: rgb(255, 255, 255);


    tbody {
        width: 100%;

        &:hover {
            background-color: rgba(1, 120, 194, 0.1);
            input {
                border:gray 1px solid !important;
                color:black;
            }
        }


        .actions {
            visibility: hidden;
        }

        &:hover .actions {
            visibility: visible;
        }

        tr {
            width: 100%;

            &:hover i {
                visibility: visible;
            }


        }
    }
}

.itineraries-actions-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

person-row,
new-person-row {
    display: flex;
    flex-direction: column;
    width: 100%;

    tbody {
        width: 100%;


        .header_row {
            .room_first_name {
                position: relative;
                left: 8px;
            }

            .room_last_name {
                position: relative;
                left: -18px;
            }

            .room_age {
                position: relative;
                left: -40px;
            }

            .room_phone {
                position: relative;
                left: -57px;
            }

            .room_email {
                position: relative;
                left: -80px;
            }

            .room_special_request {
                position: relative;
                left: -104px;
            }

        }

        tr {
            height: 35px;
            width: 100%;
            display: flex;

            td {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 90%;
                margin-left: 42px;
            }
        }

        tr:last-child {
            height: auto;
        }
    }
}

comment-row {
    width: 100%;

    textarea {
        width: 100%;
    }
}

activity-level,
vehicle-requested,
lodging-level {
    tr {
        display: flex;
        width: 100%;

        td {
            input.form-control{
                width:100% !important;
            }
            display: flex;
            flex-direction: column;
        }
    }
}

.comments_td .comments_input {
    justify-content: initial !important;
    width: 92%;
    height: 25px;
}

.dialogdemoBasicUsage #popupContainer {
    position: relative;
}

.dialogdemoBasicUsage .footer {
    width: 100%;
    text-align: center;
    margin-left: 20px;
}

.dialogdemoBasicUsage .footer,
.dialogdemoBasicUsage .footer>code {
    font-size: 0.8em;
    margin-top: 50px;
}

.dialogdemoBasicUsage button {
    width: 200px;
}

.dialogdemoBasicUsage div#status {
    color: #c60008;
}

.dialogdemoBasicUsage .dialog-demo-prerendered md-checkbox {
    margin-bottom: 0;
}

.itinerary_days_action {
    visibility: hidden;
    display: flex;
    flex-direction: row;
    width: 100px;
    justify-content: space-around;
}

.trip_day_details_row {
    &:hover {
        .itinerary_days_action {
            visibility: visible;
        }
    }
}

md-menu-bar {
    width:100%;
    position: fixed;
    background-color:#0178c2;
    display:flex;
    flex-direction:row;
    align-items:center;
    .logoContainer {
        display: inline-block;
    }
    md-menu-content {
        max-height: 90vh;
        background-color:#0178c2;
        color:white;
        display:flex;

        flex-direction:row;
        a {
            justify-content:center;
            align-items:center;
            height: 59px !important;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            display: flex !important;
            align-items:center;
            border-radius: 4px;
            &.active {
                background-color:white;
                color:#0178c2;
                height:100%;
            }
        }
    }
}
.main-menu-bottom{
    a:focus {
        color:white;
    }
}

.main-menu-item{
    margin-right: 10px;
}

md-menu-content.md-menu-bar-menu.md-dense {
    max-height: none;
    padding: initial;
}

#received_payments {
    border-radius: 5px;
    width: 310px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
    background-color: #fafafa;
    border-top: 0px;

    .received-payments-head{
        width: 100%;
        height: 32px;
        background-color: #0178c2;
        color: #ffffff;

        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .received-payments-con{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .received-payments-paid{
        min-width: 60px;
        max-width: 60px;
        margin-right: 15px;
        margin-left: 15px;
    }

    .received-payments-paid-in{
        min-width: 60px;
        max-width: 60px;
        height: 30px;
        min-width: 65px;
        max-width: 65px;
        border-radius: 2px;
        border: solid 1px #c6cbd4;
        background-color: #ffffff;
    }

    .received-payments-when{
        min-width: 150px;
        max-width: 150px;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
    }

    .received-payments-when-con{
        min-width: 100%;
        max-width: 100%;
        height: 30px;
        border-radius: 2px;
        border: solid 1px #c6cbd4;
        background-color: #ffffff;
        display: flex;
        align-items: center;
    }

    .received-payments-when-in{
        min-width: 100%;
        max-width: 100%;
        padding-right: 5px;

        .md-icon-button {
            margin: 0 0px;
        }

        input {
            max-width:60px;
            border: 0px;
        }
        .md-datepicker-input-container{
            max-width: 90px;
            border: 0px;
        }
    }

    .received-payments-opt{
        min-width: 40px;
        max-width: 40px;
        margin-right: 15px;

        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
    }

    input:focus {
        outline:0;
    }

    i:focus {
        outline:0;
    }

    .fa-plus-circle{
         color:#0178c2;
    }
}

#document_section {
    .document_heading {
        background-color:rgba(1, 120, 194, 0.6)
 !important;
        .trip_day {
            background-color:transparent
 !important;
        }
    }
}

md-menu-bar {
    .md-menu {
        display: flex;
        padding: 0;
        position: relative;
        justify-content: space-between;
        width: 100%;
    }
    .md-button {
        &:after {
            display:none !important;
        }

    }
}

daily-plan-details {
    md-menu {
        .md-button {
            top: 10px;
            position: relative;
            width: 95px !important;
            height: 25px;
            border-radius: initial !important;
            background-color: rgb(1, 120, 194);
            color:white;
            cursor: pointer !important;
        }
    }
}


.fa-trash {
    color: #d31847;
}
#room_heading {
    .fa-trash {
        color:#ffffff;
    }
}
.trip_day {
    .fa-trash {
        color:#ffffff;
    }
}

.itinerary_days_action {
    .fa-plus-circle  {
        color:#0178c2;
    }
}

vendor-item-row {
    &:hover {
        .itinerary_days_action {
            visibility: visible;
        }
    }
}

.itinerary-days-more{
    width: 10px;
    i{
        color: #0178c2;
    }
}

.md-padding {
    padding:0px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
    background-color: white;
    border-top: 0px;
}

md-nav-ink-bar {
    display: none !important;
}

.md-accent, .md-button.md-accent {
    color: #826d6d !important;
}

.nav-plans-active {
    background: #e4e1e1;
    .md-accent, .md-button.md-accent {
        font-weight: bold !important;
    }
}

.roomsInfo {
    input {
        border: solid 1px #eeeeee !important;
        /*color:#eeeeee;*/
        &:hover {
            border:1px solid black !important;
            color:black !important;
        }
    }
}

.room-con{
    width: 96%;
    margin: auto;
    padding-top: 10px;
}

.room-head{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #bababa;
}

.room-row-flex{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    padding-top: 10px;
    margin-left: auto;
    input{
        width: 9vw;
        height: 33px;
        background-color: #ffffff;
        border: 0px;
        text-align: center;
    }
    input:focus {
        outline:0;
    }
}

.room-input-con{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 12vw;
    height: 35px;
    border-radius: 2px;
    border: solid 1px #eeeeee;
    background-color: #ffffff;
    text-align: center;
}

.room-v{
    width: 15px;
    color: rgba(68, 201, 122, 0.8);
}

.room-x{
    width: 15px;
    color: rgba(211, 24, 71, 0.8);
}

.room-v:focus {
    outline:0;
}

.room-x:focus {
    outline:0;
}

.room-data{
   margin-bottom: 20px;
   border-bottom: solid 1px #e0e0e0;
}

 .room-request{
     width: 9vw;
     height: 33px;
     border-radius: 2px;
     border: 0px;
     background-color: #ffffff;
 }

.room-cell{
    min-width: 12vw;
    max-width: 12vw;
}

.room-actions{
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-around;
}

.romm-user-icon{
    color: #0178c2;;
}

.room-comment-row {
    width: 100%;
    margin-left: 23px;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.room-comments-icon{
    width: 21px;
    height: 21px;
    color: #0178c2;
    margin-right: 15px;
}

.room-comment-input{
    width: 85%;
    height: 18px;
    border: 0px;
    font-size: 12px;
    background: none;
    overflow: hidden;
}

.room-comment-input-xl{
    height: 70px;
    overflow: auto;
}

.room-comment-readMore{
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0178c2;
    margin-left: 15px;
}

.none-focus:focus {
    outline:0;
}


new-person-row {
    color:#0178c2;
}

.cruise_more_modal {
    button {
        margin-top:10px;
    }
}

.openQuoteDisabled {
    opacity:0.1;
    cursor: not-allowed;
}

    .libraries_menu {
        flex-direction:row;
        top:0px !important;
        width: 100%;
        height:60px;
        background-color: #0178c2;
        left: 0px !important;
        display: flex;
        align-items:center;
        md-menu-content{

            display: flex;
            align-items:center;
            flex-direction: row;
            background-color: #0178c2;
            button {
                color:white !important;
            }
        }
    }

.roomsInfo {
    tr{
        margin-left:42px;
        td {
            width: 14%;
            margin-left: 0px;
            background-color:initial !important;
        }
    }
}

.white-box {
    .form-group {
        width:100%;
    }
}

.add-notIncludeForm{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 60px;
}

.login-head{
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}

form {
    .form-control {
        width:100% !important;
    }
}

.requests{
    margin-top: 30px;
}

.requests-con{
    width: 98%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.requests-dropdowns{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 67%;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 3px #b6b6b6;
}

.requests-comment-con{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30%;
}
.requests-comment{
    height: 100%;
    width: 100%;
}

.requests-border{
    border-radius: 2px;
    border: solid 1px #eeeeee;
    background-color: #ffffff;
}

.requests-row{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 20px;
}

.requests-label{
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #757575;
}

.requests-selects-row{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.requests-selects{
    width: 42%;
}

.requests-selects-lod{
    width: 48%;
}

.vehicleActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: space-around;
    width: 11%;
}

.daily-plan-edit-buttons{
    justify-content: center;
    display: flex;
}

.daily-plan-edit-cancel{
    margin-right: 15px;
}
.subMenu {
    top:60px;
    background-color:#ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    md-menu {
        background-color:#ffffff;
        md-menu-content {
            background-color:#ffffff;
            color:#757575;
            a:hover {
                background-color:#e6f2f9 !important;
            }
        }

    }
}

.second-menu{
    z-index: 10;
}

md-menu-bar {
    md-menu-content {
        a:hover {
            background-color:white !important;
        }
    }
}

.btn-secondary{
  background-color: lightgrey;
}
.btn-secondary:hover{
    background-color: #9f9f9f;
}

.vndor-row-icon{
    display: flex;
    flex-direction: row;
}

.search-icon{
    font-family: Arial, 'FontAwesome';
    padding-left: 10px;
}

.vendors-select{
    height: 28px;
    font-size: 12px;
    margin-left: 20px;
}

.vendor-search-row{
    display: flex;
    flex-direction: row;
}

.vendors-page-con{
    margin-left: 300px;
}